import { get } from "http";
import { acquireApiToken } from "../msalconfig";
import { ApiRequest, AskRequest, AskResponse, ChatRequest, DataResponse, FeedbackRequest, NextChatRequest } from "./models";
import {Config} from "../config/config.js";

 function getBaseEndpoint() {
    if (process.env.NODE_ENV === "prodouction") return JSON.parse (Config).baseApiEndpoint;
    return JSON.parse (Config).baseApiEndpoint;
}

export async function askApi(options: AskRequest): Promise<AskResponse> {
    const response = await fetch("/ask", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            question: options.question,
            approach: options.approach,
            overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory
            }
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function chatApi(options: ChatRequest): Promise<AskResponse> {
    const token = await acquireApiToken();
    const url=getBaseEndpoint();
    //https://dev-api.linklogistics.com/openai-poc-dv/v1
    const response = await fetch(url+"/chat", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            "access-control-allow-credentials": "true",
            "Ocp-Apim-Subscription-Key": "ed368b42d3e04b849cd8b2b3307c6f4d",
            Authorization: `Bearer ${token}`,
            transId: options.transId ?? ""
        },
        body: JSON.stringify({
            history: options.history,
            approach: options.approach,
            allowWeb: options.allowWeb,
            allowLink: options.allowLink,
            search_method: options.search_method,
            enableSemanticSearch: options.enableSemanticSearch,
            overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                suggest_followup_questions: options.overrides?.suggestFollowupQuestions
            }
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function getDataApi(options: ApiRequest): Promise<DataResponse> {
    const token = await acquireApiToken();
    const url=getBaseEndpoint();
    //const response = await fetch("https://dev-api.linklogistics.com/openai-poc-dv/v1/getData", {
    const response = await fetch(url+"/getData", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            UserId: options.userId,
            SessionId: options.sessionId,
            authorization: `Bearer ${token}`,
            "Ocp-Apim-Subscription-Key": "ed368b42d3e04b849cd8b2b3307c6f4d",
            transId: options.transId ?? ""
        },

        body: JSON.stringify({
            message: options.message
        })
    });
    const parsedResponse: DataResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }
    return parsedResponse;
}
export async function getCitationFilePath(citation: string): Promise<any> {
    const token = await acquireApiToken();
    const headers = {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": "ed368b42d3e04b849cd8b2b3307c6f4d",
        authorization: `Bearer ${token}`
        
    };
    const url=getBaseEndpoint();
    //const response = await fetch(`https://dev-api.linklogistics.com/openai-poc-dv/v1/content/${citation}`, { headers });
    const response = await fetch(`${url}/content/${citation}`, { headers });
    const blob = await response.blob();
    const urlObject = URL.createObjectURL(blob);
    return urlObject;
    //return `https://dev-api.linklogistics.com/openai-poc-dv/v1/content/${citation}`;
}

export async function nextChatAnswerApi(options: NextChatRequest): Promise<AskResponse> {
    const token = await acquireApiToken();
    const url=getBaseEndpoint();
    //const response = await fetch("https://dev-api.linklogistics.com/openai-poc-dv/v1/next", {
        const response = await fetch(url+"/next", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            "access-control-allow-credentials": "true",
            "Ocp-Apim-Subscription-Key": "ed368b42d3e04b849cd8b2b3307c6f4d",
            Authorization: `Bearer ${token}`,
            transId: options.transId ?? ""
        },
        body: JSON.stringify({
            allContents: options.allContents,
            common_part: options.common_part,
            nextGroup: options.nextGroup,
            allowEmtec: options.allowEmtec,
            overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                suggest_followup_questions: options.overrides?.suggestFollowupQuestions
            }
        })
    });
    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }
    return parsedResponse;
}

export async function savefeedback(feedback: FeedbackRequest) {
    const token = await acquireApiToken();
    const url=getBaseEndpoint();
    const response = await fetch(url+"/Feedback", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "Ocp-Apim-Subscription-Key": "ed368b42d3e04b849cd8b2b3307c6f4d",
            transId: feedback.TransId ?? ""
        },
        body: JSON.stringify({
            FeedbackType: feedback.FeedbackType,
            Comment: feedback.Comment,
            Question: feedback.Question,
            SearchType: feedback.SearchType,
            AnswerSummary: feedback.AnswerSummary,
            TransId: feedback.TransId
        })
    });
    const parsedResponse: DataResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }
    return parsedResponse;
}