import { Stack, PrimaryButton, IconButton } from "@fluentui/react";
import { ErrorCircle24Regular } from "@fluentui/react-icons";
import { KeyboardArrowRight, KeyboardArrowLeft } from "@mui/icons-material";
import styles from "./LLAnswer.module.css";

interface Props {
    error?: string;
    onRetry: () => void;
}

export const AnswerError = ({ error, onRetry }: Props) => {
    return (
        <>
            <Stack className={styles.answerContainer} style={{ minWidth: "250px" }} verticalAlign="space-between">
                <ErrorCircle24Regular aria-hidden="true" aria-label="Error icon" primaryFill="red" />
                <Stack.Item grow>
                    <p className={styles.answerText}>{error}</p>
                </Stack.Item>
                <Stack.Item style={{ display: "flex" }}>
                    <PrimaryButton className={styles.retryButton} onClick={onRetry} text="Retry" />
                </Stack.Item>
            </Stack>
        </>
    );
};
