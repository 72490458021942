import { MsalProvider } from "@azure/msal-react";
import { Configuration, InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";
import process from "process";
import { isIE11 } from "@fluentui/react";

export const msalconfig: Configuration ={
    auth:{
        clientId:`3724c158-8056-4383-88bc-24a9ffc970e9`,
        authority:"https://login.microsoftonline.com/d6130c51-e1f1-43fa-b6cb-9634098b05a6",
        knownAuthorities:["https://login.microsoftonline.com","https://login.microsoftonline.com/*"],
        redirectUri: "/",
        postLogoutRedirectUri: "/",
    },
    cache:{
        cacheLocation:"sessionStorage",
        storeAuthStateInCookie: false
    }
};

export const apiConfig={
    resourceScopes:[`api://8ab3e011-1a1a-4ed2-91a5-646d7030e0f6/.default`]
};

export const msalInstance =new PublicClientApplication(msalconfig);

export function AuthWrapper({children}:any){
    return(
        <MsalProvider instance={msalInstance}>

            {children}
     
        </MsalProvider>
    );
}

export const loginRequest ={
    scopes:[...apiConfig.resourceScopes]
}
export const acquireApiToken= async()=>{
    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();

if(!activeAccount && accounts.length === 0){
    throw new Error("User is not logged in");
    
}
try{
const response = await msalInstance.acquireTokenSilent({
    account: activeAccount || accounts[0],
    ...loginRequest
});
if(response.accessToken){
    return response.accessToken;
}
}catch(error)
{
if(error instanceof InteractionRequiredAuthError){
    console.error(error);
    //return msalInstance.acquireTokenPopup(silentRequest)
}
console.error(error);
return ""
}

};

export const acquireApiTokenNonAsync= ()=>{
    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();

if(!activeAccount && accounts.length === 0){
    throw new Error("User is not logged in");
    
}
try{
msalInstance.acquireTokenSilent({
    account: activeAccount || accounts[0],
    ...loginRequest
}).then((response)=>{
if(response.accessToken){
    return response.accessToken;
}
});
}catch(error)
{
if(error instanceof InteractionRequiredAuthError){
    console.error(error);
    //return msalInstance.acquireTokenPopup(silentRequest)
}
console.error(error);
return ""
}

};

