import { useMemo, useState, useEffect } from "react";
import { Stack, IconButton, TextField, StackItem } from "@fluentui/react";
import DOMPurify from "dompurify";
import { OpenAI } from "./OpenAI";

import styles from "./LLWebAnswer.module.css";

import { AskResponse, getCitationFilePath } from "../../api";
import { parseAnswerToHtml } from "./WebAnswerParser";
import { AnswerIcon } from "./WebAnswerIcon";
import { ThumbsDownFilledIcon, ThumbsDownIcon, ThumbsUpFilledIcon, ThumbsUpIcon } from "./WebAnswerFeedBack";

interface Props {
    answer: AskResponse;
    isSelected?: boolean;
    onCitationClicked: (filePath: string) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
}

export const WebAnswer = ({
    answer,
    isSelected,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions
}: Props) => {
    const answerToParse= answer.externalAnswer? answer.externalAnswer:"";
    const parsedAnswerFromWeb = useMemo(() => parseAnswerToHtml(answerToParse, onCitationClicked), [answer]);

    const sanitizedWebAnswerHtml = DOMPurify.sanitize(parsedAnswerFromWeb.answerHtml);
    const [thumbsUpClicked, ClickThumbsUp] = useState(false);
    const [thumbsDownClicked, ClickThumbsDown] = useState(false);
    const [hideFeedback, SetHideFeedback] = useState(true);
    const [feedbackValue, SetFeedbackText] = useState<string>("");
    const onThumbsUpclick = () => {
        ClickThumbsUp(true);
        ClickThumbsDown(false);
    };

    const onThumbsUpFilledclick = () => {
        ClickThumbsUp(false);
    };
    const onThumbsDownclick = () => {
        ClickThumbsDown(true);
        ClickThumbsUp(false);
        SetHideFeedback(false);
    };

    const onThumbsDownFilledclick = () => {
        ClickThumbsDown(false);
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            SetHideFeedback(true);
        }
    };

    const onAnswerChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        SetFeedbackText(newValue || "");
    };
    return (
        <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">
            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between">
                    <OpenAI />
                    {/* <div>
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "Lightbulb" }}
                            title="Show thought process"
                            ariaLabel="Show thought process"
                            onClick={() => onThoughtProcessClicked()}
                            disabled={!answer.thoughts}
                        />
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "ClipboardList" }}
                            title="Show supporting content"
                            ariaLabel="Show supporting content"
                            onClick={() => onSupportingContentClicked()}
                            disabled={!answer.data_points.length}
                        />
                    </div> */}
                </Stack>
            </Stack.Item>
            <Stack.Item grow>
                <div className={styles.answerText} dangerouslySetInnerHTML={{ __html: sanitizedWebAnswerHtml }}></div>
            </Stack.Item>

            {!!parsedAnswerFromWeb.citations.length && (
                <Stack.Item>
                    <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                        <span className={styles.citationLearnMore}>Citations:</span>
                        {parsedAnswerFromWeb.citations.map((x, i) => {
                            const path = getCitationFilePath(x);
                            return (
                                <a key={i} className={styles.citation} title={x} href={x} target="_blank">
                                    {`${++i}. ${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}

            {!!parsedAnswerFromWeb.followupQuestions.length && showFollowupQuestions && onFollowupQuestionClicked && (
                <Stack.Item>
                    <Stack
                        horizontal
                        wrap
                        className={`${!!parsedAnswerFromWeb.citations.length ? styles.followupQuestionsList : ""}`}
                        tokens={{ childrenGap: 6 }}
                    >
                        <span className={styles.followupQuestionLearnMore}>Follow-up questions:</span>
                        {parsedAnswerFromWeb.followupQuestions.map((x, i) => {
                            return (
                                <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                    {`${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}
            {
                <Stack.Item>
                    <Stack horizontal horizontalAlign="space-between" style={{ float: "right", textAlign: "right" }}>
                        <div>
                            {!thumbsUpClicked && (
                                <span onClick={onThumbsUpclick}>
                                    <ThumbsUpIcon></ThumbsUpIcon>
                                </span>
                            )}
                            {thumbsUpClicked && (
                                <span onClick={onThumbsUpFilledclick}>
                                    <ThumbsUpFilledIcon></ThumbsUpFilledIcon>
                                </span>
                            )}
                            {!thumbsDownClicked && (
                                <span onClick={onThumbsDownclick}>
                                    <ThumbsDownIcon></ThumbsDownIcon>
                                </span>
                            )}
                            {thumbsDownClicked && (
                                <span onClick={onThumbsDownFilledclick}>
                                    <ThumbsDownFilledIcon></ThumbsDownFilledIcon>
                                </span>
                            )}
                        </div>
                    </Stack>
                </Stack.Item>
            }
            {
                <StackItem>
                    <Stack>
                        <div>
                            {thumbsDownClicked && !hideFeedback && (
                                <TextField
                                    className={styles.questionInputTextArea}
                                    placeholder={"your feedback"}
                                    resizable={false}
                                    borderless
                                    value={feedbackValue}
                                    onChange={onAnswerChange}
                                onKeyDown={e => onEnterPress(e)}
                                />
                            )}
                        </div>
                    </Stack>
                </StackItem>
            }
        </Stack>
    );
};
