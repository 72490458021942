import { ImageLoadState, Pivot, PivotItem } from "@fluentui/react";
import DOMPurify from "dompurify";

import styles from "./AnalysisPanel.module.css";

import { SupportingContent } from "../SupportingContent";
import { AllContents, AskResponse } from "../../api";
import { AnalysisPanelTabs } from "./AnalysisPanelTabs";
import PdfDocumentViewer from "../PdfViewer/PdfDocumentViewer";
import { useAppSelector } from "../../hooks";
import{Oval} from "react-loader-spinner";

interface Props {
    className: string;
    activeTab: AnalysisPanelTabs;
    onActiveTabChanged: (tab: AnalysisPanelTabs) => void;
    activeCitation: string | undefined;
    citationHeight: string;
    answer: AskResponse|AllContents;
    common_thoughts?: string;
}

const pivotItemDisabledStyle = { disabled: true, style: { color: "grey" } };

export const AnalysisPanel = ({ answer, activeTab, activeCitation, citationHeight, className, onActiveTabChanged,common_thoughts }: Props) => {
    const activeCitationState = useAppSelector((state)=>state.citationState);
    const isDisabledThoughtProcessTab: boolean = !answer.thoughts;
    const isDisabledSupportingContentTab: boolean = !answer.data_points.length;
    const isDisabledCitationTab: boolean = !activeCitation;
    const options=useAppSelector((state)=>state.citationState);
    const sanitizedThoughts = DOMPurify.sanitize(common_thoughts +'<br/>'+ answer.thoughts!);

    return (
        <Pivot
            className={className}
            selectedKey={activeTab}
            onLinkClick={pivotItem => pivotItem && onActiveTabChanged(pivotItem.props.itemKey! as AnalysisPanelTabs)}
        >
            <PivotItem
                itemKey={AnalysisPanelTabs.ThoughtProcessTab}
                headerText="Thought process"
                headerButtonProps={isDisabledThoughtProcessTab ? pivotItemDisabledStyle : undefined}
            >
                <div className={styles.thoughtProcess} dangerouslySetInnerHTML={{ __html: sanitizedThoughts }}></div>
            </PivotItem>
            <PivotItem
                itemKey={AnalysisPanelTabs.SupportingContentTab}
                headerText="Supporting content"
                headerButtonProps={isDisabledSupportingContentTab ? pivotItemDisabledStyle : undefined}
            >
                <SupportingContent supportingContent={answer.data_points} />
            </PivotItem>
            <PivotItem
                itemKey={AnalysisPanelTabs.CitationTab}
                headerText="Citation"
                headerButtonProps={isDisabledCitationTab ? pivotItemDisabledStyle : undefined}
            >
                {!activeCitationState.isFailure && !activeCitationState.isFetching && activeCitation &&(

                <iframe title="Citation" src={activeCitationState.citation} width="100%" height={citationHeight} />
                )}
                {activeCitationState.isFetching && (<div style={{textAlign: "center",padding:"15px"}}><Oval
                height={40}
                width={40}
                color="#808080"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#808080"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
              </div>
              )}
            </PivotItem>
        </Pivot>
    );
};
