import { AskResponse, chatApi, ChatRequest, Options } from "../api";
import{ createAsyncThunk, PayloadAction, createSlice} from "@reduxjs/toolkit";

export interface ISearchOptions{
    options: Options;
    isFetching:boolean;
    isFailure:boolean;
}

export const initialDataState :Options ={
    allowLink:true,
    allowWeb:false,
    allowData:false,
    search_method:"cognitive",
    enableSemanticSearch:false,
}

export const initialState :ISearchOptions ={
options:initialDataState,
isFailure:false,
isFetching:false
}

const searchOptionsSlice = createSlice({
    name:"searchoptions",
    initialState,
    extraReducers: (builder:any) => {

        builder.addCase(
          fetchChatAnswers.fulfilled,
          (state:any, action: PayloadAction<Options>) => {
            state.options.allowLink = action.payload.allowLink;
            state.options.allowWeb = action.payload.allowWeb;
            state.options.allowData = action.payload.allowData;
            state.options.search_method = action.payload.search_method;
            state.options.enableSemanticSearch = action.payload.enableSemanticSearch;
            state.isFetching = false;
            state.isFailure = false;
          },
    
        );
    
        builder.addCase(fetchChatAnswers.pending, (state:any) => {
          state.isFetching = true;
          state.isFailure = false;
        });
    
        builder.addCase(fetchChatAnswers.rejected, (state:any) => {
          state.isFetching = false;
          state.isFailure = true;
        });
    
      },
    
      reducers: {},
    
    });
    
     
    
    export const searchOptionsReducer = searchOptionsSlice.reducer;
  
    
    
    export const fetchChatAnswers = createAsyncThunk(
    
      `/getSearchOptions`,
    
      async (requestData: Options) => {
    
        return requestData;
      },
    
    );