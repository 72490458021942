import { ThumbsDownFilledIcon, ThumbsDownIcon, ThumbsUpFilledIcon, ThumbsUpIcon } from "./DbAnswerFeedback";
import { FullLogo } from "./LinkLogo";
import { useMemo, useState } from "react";
import { Stack, IconButton, TextField } from "@fluentui/react";
import styles from "./LLDbAnswer.module.css";
interface Props {
    saveFeedback: (comment: string, feedbackType: string) => any;
}
export const FeedBack = ({ saveFeedback }: Props) => {
    const [thumbsUpClicked, ClickThumbsUp] = useState(false);
    const [thumbsDownClicked, ClickThumbsDown] = useState(false);
    const [hideFeedback, SetHideFeedback] = useState(true);
    const [feedbackValue, SetFeedbackText] = useState<string>("");

    const onThumbsUpclick = () => {
        ClickThumbsUp(true);
        ClickThumbsDown(false);
        saveFeedback(feedbackValue, "Like");
    };

    const onThumbsUpFilledclick = () => {
        ClickThumbsUp(false);
    };
    const onThumbsDownclick = () => {
        ClickThumbsDown(true);
        ClickThumbsUp(false);
        SetHideFeedback(false);
    };

    const onThumbsDownFilledclick = () => {
        ClickThumbsDown(false);
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            SetHideFeedback(true);
            saveFeedback && saveFeedback(feedbackValue, "Dislike");
        }
    };

    const onAnswerChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        SetFeedbackText(newValue || "");
    };

    return (
        <div style={{ float: "right", textAlign: "right", marginRight: "15px" }}>
            <div>
                {!thumbsUpClicked && (
                    <span onClick={onThumbsUpclick}>
                        <ThumbsUpIcon></ThumbsUpIcon>
                    </span>
                )}
                {thumbsUpClicked && (
                    <span onClick={onThumbsUpFilledclick}>
                        <ThumbsUpFilledIcon></ThumbsUpFilledIcon>
                    </span>
                )}
                {!thumbsDownClicked && (
                    <span onClick={onThumbsDownclick}>
                        <ThumbsDownIcon></ThumbsDownIcon>
                    </span>
                )}
                {thumbsDownClicked && (
                    <span onClick={onThumbsDownFilledclick}>
                        <ThumbsDownFilledIcon></ThumbsDownFilledIcon>
                    </span>
                )}
                {thumbsDownClicked && !hideFeedback && (
                    <TextField
                        className={styles.questionInputTextArea}
                        placeholder={"your feedback"}
                        resizable={false}
                        borderless
                        value={feedbackValue}
                        onChange={onAnswerChange}
                        onKeyDown={e => onEnterPress(e)}
                    />
                )}
            </div>
        </div>
    );
};
