import { AskResponse, chatApi, ChatRequest, getCitationFilePath, Options } from "../api";
import{ createAsyncThunk, PayloadAction, createSlice} from "@reduxjs/toolkit";

export interface ICitation{
    citation: any;
    isFetching:boolean;
    isFailure:boolean;
}


export const initialState :ICitation ={
    citation:null,
isFailure:false,
isFetching:false
}

const searchOptionsSlice = createSlice({
    name:"citations",
    initialState,
    extraReducers: (builder:any) => {

        builder.addCase(
            getCitation.fulfilled,
          (state:any, action: PayloadAction<any>) => {
            state.citation= action.payload
            state.isFetching = false;
            state.isFailure = false;
          },
    
        );
    
        builder.addCase(getCitation.pending, (state:any) => {
          state.isFetching = true;
          state.isFailure = false;
        });
    
        builder.addCase(getCitation.rejected, (state:any) => {
          state.isFetching = false;
          state.isFailure = true;
        });
    
      },
    
      reducers: {},
    
    });
    
     
    
    export const citationReducer = searchOptionsSlice.reducer;
  
    
    
    export const getCitation = createAsyncThunk(
    
      `/getCitation`,
    
      async (citation:string) => {
    
        return await getCitationFilePath(citation);
      },
    
    );