import { combineReducers } from '@reduxjs/toolkit';
import { citationReducer } from './slices/CitationSlice';

 

import { searchOptionsReducer } from './slices/SearchOptionsSlice';


 

const rootReducer = combineReducers({

  searchOptionsState: searchOptionsReducer,
  citationState:citationReducer


});

 

export type RootState = ReturnType<typeof rootReducer>;

 

export default rootReducer;