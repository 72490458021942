import { ChangeEvent, FormEvent, useState } from "react";
import { Grid } from "react-loader-spinner";
import { Options } from "../../api/models";
import { useAppDispatch } from "../../hooks";
import { fetchChatAnswers } from "../../slices/SearchOptionsSlice";
import styles from "./SearchSettings.module.css";

const SearchSettings = () => {
    const dispatch = useAppDispatch();
    const [linkOptionActive, setLinkOptionActive] = useState<boolean>(true);
    const [webOptionActive, setWebOptionActive] = useState<boolean>(false);
    const [dataOptionActive, setDataOptionActive] = useState<boolean>(false);
    const [searchMethod, setSearchMethod] = useState<string>("cognitive");

    const handleLinkOptionSelected = () => {
        setLinkOptionActive(true);
        setWebOptionActive(false);
        setDataOptionActive(false);
        const options: Options = {
            allowLink: true,
            allowWeb: false,
            allowData: false,
            search_method: searchMethod,
            enableSemanticSearch: false
        };
        dispatch<any>(fetchChatAnswers(options));
    };

    const handleDataOptionSelected = () => {
        setLinkOptionActive(false);
        setWebOptionActive(false);
        setDataOptionActive(true);
        const options: Options = {
            allowLink: false,
            allowWeb: false,
            allowData: true,
            search_method: searchMethod,
            enableSemanticSearch: false
        };
        dispatch<any>(fetchChatAnswers(options));
    };
    function handdleSearchMethod(event: ChangeEvent<HTMLSelectElement>): void {
        setSearchMethod(event.target.value);
        const options: Options = {
            allowLink: linkOptionActive,
            allowWeb: webOptionActive,
            allowData: dataOptionActive,
            search_method: event.target.value,
            enableSemanticSearch: false
        };
        dispatch<any>(fetchChatAnswers(options));
    }
    const [viewSettings, setViewSettings] = useState(true);
    function handdleToggleSetting(event: FormEvent<HTMLSpanElement>): void {
        alert("aaaaa");
        setViewSettings(!viewSettings);
    }
    return (
        <div>
            <ul>
                <li className={styles.fontBold}>Include Result from</li>
                <li>
                    <label className={styles.checkboxContainer}>
                        <input type="radio" checked={linkOptionActive && !webOptionActive} name="radio" onChange={handleLinkOptionSelected}></input>
                        Documents
                        <span className={styles.checkmark}></span>
                    </label>
                </li>
                <li>
                    {" "}
                    <label className={styles.checkboxContainer}>
                        <input
                            type="radio"
                            checked={!linkOptionActive && !webOptionActive && dataOptionActive}
                            name="radio"
                            onChange={handleDataOptionSelected}
                        ></input>
                        Data
                        <span className={styles.checkmark}></span>
                    </label>
                </li>

                {
                    //<li className={styles.checkboxContainer}>
                    //  <span onChange={(event)=>handdleToggleSetting(event)}>Settings</span>
                    //</li>
                    // <div style={{display:"table-column", width:"300px", height:"auto", backgroundColor:"red"}}>
                    // <div>
                    // <span> Sematic Search: </span>
                    // </div>
                    // <div>
                    // <ul>
                    // <li> <label className={styles.fontBold}>
                    // Search Method
                    // </label> </li>
                    // <li style={{font:"12px",borderBlock:"border-size"}}>
                    // <select name="search_method" className={styles.Select}
                    //  onChange={(event)=>handdleSearchMethod(event)} value={searchMethod}>
                    //     <option value="cognitive">Cognitive Search</option>
                    //      <option value="solr">Solr Search</option>
                    //     <option value="qdrant">Qdrant Search</option>
                    // </select>
                    //         </li></ul>
                    //     </div>
                    // </div>
                }
                {/* <li className={styles.checkboxContainer}>  
                          <label className={styles.fontBold}>
                            Search Method        
                            </label> </li>
                            <li style={{font:"12px",borderBlock:"border-size"}}>                 
                            <select name="search_method" className={styles.Select}
                             onChange={(event)=>handdleSearchMethod(event)} value={searchMethod}>
                                <option value="cognitive">Cognitive Search</option>
                                 <option value="solr">Solr Search</option> 
                                <option value="qdrant">Qdrant Search</option>
                            </select>                         
                        </li> */}
            </ul>
        </div>
    );
};

export default SearchSettings;
