import { ThumbLike24Regular, ThumbDislike24Regular, ThumbLike24Filled, ThumbDislike24Filled } from "@fluentui/react-icons";

export const ThumbsUpIcon = () => {
    return <ThumbLike24Regular primaryFill={"rgb(178, 97, 58)"} aria-hidden="true" aria-label="Like" height={"25px"} width={"25px"} />;
};
export const ThumbsDownIcon = () => {
    return <ThumbDislike24Regular primaryFill={"rgb(178, 97, 58)"} aria-hidden="true" aria-label="Unlike" height={"25px"} width={"25px"} />;
};

export const ThumbsUpFilledIcon = () => {
    return <ThumbLike24Filled primaryFill={"rgb(178, 97, 58)"} aria-hidden="true" aria-label="Like" height={"25px"} width={"25px"} />;
};
export const ThumbsDownFilledIcon = () => {
    return <ThumbDislike24Filled primaryFill={"rgb(178, 97, 58)"} aria-hidden="true" aria-label="Unlike" height={"25px"} width={"25px"} />;
};