import React, { useState } from 'react'

import DocViewer from "@cyntler/react-doc-viewer";


// Create Document Component
const PdfDocumentViewer = (docs:any) => {
    const doc = [
        //   { uri: "https://url-to-my-pdf.pdf" },
          { uri: docs }, // Local File
        ];
return(
    <DocViewer documents={doc} />
      
)};

export default PdfDocumentViewer;