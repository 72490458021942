import { useState } from "react";
import { Stack, IconButton, TextField } from "@fluentui/react";
import { KeyboardArrowRight, KeyboardArrowLeft } from "@mui/icons-material";
import styles from "./LLDbAnswer.module.css";
import { DataResponse } from "../../api";
import { FullLogo } from "./LinkLogo";
import { FeedBack } from "./DbFeedback";

interface Props {
    answer: DataResponse;
    isSelected?: boolean;
    saveFeedback: (comment: string, feedbackType: string) => any;
}


export const DbAnswer = ({ answer, isSelected, saveFeedback }: Props) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const css = {
        Scrollbar: {
            "max-width": "750px",
            display: "flex",
            "max-height": "300px",
            overflow: "auto",
            marginTop: "15px",
            marginLeft: "9px",
            marginRight: "9px"
        }
    };
    const Tabulate = () => {
        var arr = answer.data;
        if (arr.length > 0) {
            const cols = Object.keys(arr[0]);
            var datastructure = answer.resultType;
            const header = () => {
                return cols.map(e => (
                    <th key={e} align="left" className={`${styles.head}`}>
                        {e}
                    </th>
                ));
            };
            const goToPreviousPage = () => {
                if (currentPage > 1) {
                    setCurrentPage(currentPage - 1);
                }
            };

            const goToNextPage = () => {
                const totalPages = Math.ceil(arr.length / itemsPerPage);

                if (currentPage < totalPages) {
                    setCurrentPage(currentPage + 1);
                }
            };
            const totalPages = Math.ceil(arr.length / itemsPerPage);

            const isFirstPage = currentPage === 1;

            const isLastPage = currentPage === totalPages;

            if (datastructure.toLowerCase() === "scalar") {
                return (
                    <>
                        <div className={`${styles.marginBottom}`}>
                            <table className={`${styles.table}`}>
                                <tbody>
                                    {arr.map((r: any, index1) => (
                                        <tr key={"row_" + index1} className={`${styles.scalarbackgroundcolor}`}>
                                            {cols.map((col, index) => (
                                                <td key={col + "_" + index} align="left">
                                                    {col}: {r[col]}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className={`${styles.pagination}`} style={{ width: "100%" }}>
                            <FeedBack saveFeedback={saveFeedback} />
                        </div>
                    </>
                );
            } else {
                const indexOfLastItem = currentPage * itemsPerPage;

                const indexOfFirstItem = indexOfLastItem - itemsPerPage;

                const currentItems = arr.slice(indexOfFirstItem, indexOfLastItem);
                return (
                    <div>
                        <div style={css.Scrollbar} className={`${styles.marginBottom}`}>
                            <table className={`${styles.table}`}>
                                <tbody>
                                    <tr className={`${styles.fix_header}`}>{header()}</tr>
                                    {currentItems.map((r: any) => (
                                        <tr className={`${styles.backgroundcolorGray} ${styles.hovercolor}`}>
                                            {cols.map(col => (
                                                <td key={col} align="left" className={`${styles.boder}`}>
                                                    {typeof r[col] === "object" ? JSON.stringify(r[col]) : r[col]}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <table className={`${styles.pagination}`} style={{ width: "100%" }}>
                                <tr>
                                    <div id="pagination" style={{ display: "flex", verticalAlign: "center", justifyContent: "space-between" }}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <IconButton style={{ margin: "0 5px" }} onClick={goToPreviousPage} disabled={isFirstPage}>
                                                <KeyboardArrowLeft style={{ color: "rgb(178, 97, 58)" }} />{" "}
                                            </IconButton>
                                            <div>
                                                <span style={{ margin: "0 5px" }}>{indexOfFirstItem + 1}</span>
                                                <span style={{ margin: "0 1px" }}>{"-"}</span>
                                                <span style={{ margin: "0 5px" }}>
                                                    {" "}
                                                    {!isLastPage ? indexOfLastItem : Math.min(indexOfLastItem, arr.length)}
                                                </span>
                                                <span style={{ margin: "0 1px" }}>{"of"}</span>
                                                <span style={{ margin: "0 5px" }}> {arr.length}</span>
                                            </div>
                                            <IconButton style={{ margin: "0 5px" }} onClick={goToNextPage} disabled={isLastPage}>
                                                <KeyboardArrowRight style={{ color: "rgb(178, 97, 58)" }} />
                                            </IconButton>
                                        </div>
                                        <FeedBack saveFeedback={saveFeedback} />
                                    </div>
                                </tr>
                            </table>
                        </div>
                    </div>
                );
            }
        } else {
            return (
                <>
                    <div style={css.Scrollbar} className={`${styles.marginBottom}`}>
                        Sorry, we did not get any result for this question.
                    </div>
                    <div className={`${styles.pagination}`} style={{ width: "100%" }}>
                        <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                            <FeedBack saveFeedback={saveFeedback} />
                        </div>
                    </div>
                </>
            );
        }
    };

    return (
        <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">
            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between">
                    <FullLogo />
                </Stack>
            </Stack.Item>
            <Stack.Item grow>
                <Tabulate />
            </Stack.Item>
        </Stack>
    );
};
