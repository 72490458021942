import { Outlet, NavLink, Link } from "react-router-dom";

import bot1 from "../../assets/bot1.svg";

import styles from "./LLLayout.module.css";
import LLLogo from "../../assets/LLLogo.svg";
import GPTLogo from "../../assets/GPT.svg";
import SearchSettings from "../../components/SearchOptions/SearchSettings";
import { SignOut24Regular } from "@fluentui/react-icons";
import { Avatar } from '@fluentui/react-avatar';
import { msalInstance } from "../../msalconfig";
import { useState } from "react";

const Layout = () => {
    const[user,setUser] = useState(msalInstance.getAllAccounts()[0].username);
const handleLogout = async() =>{
    const currentAccount = msalInstance.getAllAccounts();
    await msalInstance.logoutRedirect({
        account: currentAccount[0],
        postLogoutRedirectUri: "/"
    });
};
const showUserName= () =>{
    const currentAccount = msalInstance.getAllAccounts();
    setUser(currentAccount[0].username);
}
    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <nav>
                        <ul className={styles.headerNavList}>
                            <li className={styles.headerNavLeftMargin}>
                                <img src={LLLogo} height={"18px"} className={styles.LLLogo} />
                                <img src={GPTLogo} height={"14px"} className={styles.GPTLogo} />
                            </li>

                        </ul>
                    </nav>
                </div>
                <div className={styles.headerList}>
                   <SearchSettings  />
                </div>
                <Avatar size={48} name={user} className={styles.headerContact}/>
                <button className={styles.headerLogout} onClick={handleLogout} >
                <SignOut24Regular primaryFill="white" ></SignOut24Regular>
                </button>
            </header>

            <Outlet />
        </div>
    );
};

export default Layout;
