import { useMemo, useState } from "react";
import { Stack, IconButton, TextField, PrimaryButton } from "@fluentui/react";
import DOMPurify from "dompurify";

import styles from "./LLAnswer.module.css";

import { AllContents, AskResponse, getCitationFilePath } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";
import { ThumbsDownFilledIcon, ThumbsDownIcon, ThumbsUpFilledIcon, ThumbsUpIcon } from "./AnswerFeedback";
import { FullLogo } from "./LinkLogo";
import { useAppDispatch } from "../../hooks";
import { getCitation } from "../../slices/CitationSlice";
import { KeyboardArrowRight, KeyboardArrowLeft } from "@mui/icons-material";
interface Props {
    answer: AskResponse | AllContents;
    isSelected?: boolean;
    onCitationClicked: (filePath: string) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
    onClickViewMultipleAnswer?: (event: any, name: string) => any;
    totalGroups?: number;
    selectedGroup?: number;
    saveFeedback?: (comment: string, feedbackType: string) => any | undefined;
}

export const Answer = ({
    answer,
    isSelected,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions,
    onClickViewMultipleAnswer,
    totalGroups,
    selectedGroup,
    saveFeedback
}: Props) => {
    function getCitationFromDataPoint(dataPoints: string[], answer: string) {
        let followupQuestions: string[];
        let parsedAnswer = answer.replace(/<<([^>>]+)>>/g, (match, content) => {
            followupQuestions.push(content);
            return "";
        });
        const parts = parsedAnswer.split(/\[([^\]]+)\]/g);
        let citations: string[] = [];
        parts.map((part, index) => {
            if (index % 2 === 0) {
                return part;
            } else {
                let citationIndex: number;
                if (citations.indexOf(part) !== -1) {
                    citationIndex = citations.indexOf(part) + 1;
                } else {
                    citations.push(part);
                    citationIndex = citations.length;
                }
            }
        });

        if (citations.length === 0) {
            let citations1 = dataPoints.map(item => `[${item.split(":")[0].trim()}]`).join(" ");
            //return answer + " " + citations1;
            return (
                (answer !== "" ? answer : "I'am Sorry, I am unable to provide a summary. But You may find the relevant information below citations. ") +
                citations1
            );
        }
        return answer;
    }

    const answerToParse = answer && answer.answer !== undefined && answer.answer !== null ? answer.answer : "";
    //const parsedAnswer = useMemo(() => parseAnswerToHtml(getCitationFromDataPoint(answer.data_points, answerToParse), onCitationClicked), [answer]);
    const parsedAnswer = parseAnswerToHtml(getCitationFromDataPoint(answer.data_points, answerToParse), onCitationClicked);
    const dispatch = useAppDispatch();
    const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);
    const [thumbsUpClicked, ClickThumbsUp] = useState(false);
    const [thumbsDownClicked, ClickThumbsDown] = useState(false);
    const [hideFeedback, SetHideFeedback] = useState(true);
    const [feedbackValue, SetFeedbackText] = useState<string>("");
    const onThumbsUpclick = () => {
        ClickThumbsUp(true);
        ClickThumbsDown(false);
        saveFeedback && saveFeedback(feedbackValue, "Like");
    };

    const onThumbsUpFilledclick = () => {
        ClickThumbsUp(false);
    };
    const onThumbsDownclick = () => {
        ClickThumbsDown(true);
        ClickThumbsUp(false);
        SetHideFeedback(false);
        saveFeedback && saveFeedback(feedbackValue, "Dilike");
    };

    const onThumbsDownFilledclick = () => {
        ClickThumbsDown(false);
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            SetHideFeedback(true);
            saveFeedback && saveFeedback(feedbackValue, "Dislike");
        }
    };

    const onAnswerChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        SetFeedbackText(newValue || "");
    };

    return (
        <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} style={{ minWidth: "250px" }} verticalAlign="space-between">
            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between">
                    <FullLogo />
                    <div>
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "Lightbulb" }}
                            title="Show thought process"
                            ariaLabel="Show thought process"
                            onClick={() => onThoughtProcessClicked()}
                            disabled={!answer?.thoughts}
                        />
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "ClipboardList" }}
                            title="Show supporting content"
                            ariaLabel="Show supporting content"
                            onClick={() => onSupportingContentClicked()}
                            disabled={!answer?.data_points.length}
                        />
                    </div>
                </Stack>
            </Stack.Item>

            <Stack.Item grow>
                <div className={styles.answerText} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></div>
            </Stack.Item>

            {!!parsedAnswer.citations.length && (
                <Stack.Item>
                    <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                        <>
                            <span className={styles.citationLearnMore}>Citations:</span>
                            {parsedAnswer.citations.map((x, i) => {
                                return (
                                    <a key={i} className={styles.citation} title={x} onClick={async () => onCitationClicked(dispatch<any>(getCitation(x)))}>
                                        {`${++i}. ${x}`}
                                    </a>
                                );
                            })}
                        </>
                    </Stack>
                </Stack.Item>
            )}

            {!!parsedAnswer.followupQuestions.length && showFollowupQuestions && onFollowupQuestionClicked && (
                <Stack.Item>
                    <Stack horizontal wrap className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                        <span className={styles.followupQuestionLearnMore}>Follow-up questions:</span>
                        {parsedAnswer.followupQuestions.map((x, i) => {
                            return (
                                <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                    {`${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}

            {
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "15px", alignItems: "center" }}>
                    {totalGroups !== 1 ? (
                        <Stack.Item>
                            <Stack style={{ flexFlow: "row" }}>
                                <IconButton
                                    title="Previous Answer"
                                    name="prevAnswer"
                                    onClick={event => onClickViewMultipleAnswer && onClickViewMultipleAnswer(event, "prevAnswer")}
                                >
                                    <b>
                                        <KeyboardArrowLeft style={{ color: "rgb(178, 97, 58)" }} />{" "}
                                    </b>
                                </IconButton>
                                <span style={{ verticalAlign: "center", paddingTop: "5px", fontSize: "inherit" }}>{selectedGroup + "/" + totalGroups}</span>
                                <IconButton
                                    title="Next Answer"
                                    name="nextAnswer"
                                    onClick={event => onClickViewMultipleAnswer && onClickViewMultipleAnswer(event, "nextAnswer")}
                                >
                                    <b>
                                        <KeyboardArrowRight style={{ color: "rgb(178, 97, 58)" }} />
                                    </b>
                                </IconButton>
                            </Stack>{" "}
                        </Stack.Item>
                    ) : (
                        <span></span>
                    )}
                    <div>
                        {!thumbsUpClicked && (
                            <span onClick={onThumbsUpclick}>
                                <ThumbsUpIcon></ThumbsUpIcon>
                            </span>
                        )}
                        {thumbsUpClicked && (
                            <span onClick={onThumbsUpFilledclick}>
                                <ThumbsUpFilledIcon></ThumbsUpFilledIcon>
                            </span>
                        )}
                        {!thumbsDownClicked && (
                            <span onClick={onThumbsDownclick}>
                                <ThumbsDownIcon></ThumbsDownIcon>
                            </span>
                        )}
                        {thumbsDownClicked && (
                            <span onClick={onThumbsDownFilledclick}>
                                <ThumbsDownFilledIcon></ThumbsDownFilledIcon>
                            </span>
                        )}
                        {thumbsDownClicked && !hideFeedback && (
                            <TextField
                                className={styles.questionInputTextArea}
                                placeholder={"your feedback"}
                                resizable={false}
                                borderless
                                value={feedbackValue}
                                onChange={onAnswerChange}
                                onKeyDown={e => onEnterPress(e)}
                            />
                        )}
                    </div>
                </div>
            }
        </Stack>
    );
};
