import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import { Provider as ReduxProvider } from 'react-redux';

import "./index.css";

import Layout from "./pages/layout/Layout";
import NoPage from "./pages/NoPage";
import OneShot from "./pages/oneshot/OneShot";
import Chat from "./pages/chat/Chat";
import ChatWithOnlyAnalysisPanel from "./pages/chat/ChatWithOnlyAnalysisPanel";
import store from "./store";
import { AuthenticatedTemplate,MsalAuthenticationTemplate,UnauthenticatedTemplate } from "@azure/msal-react";
import { AuthWrapper } from "./msalconfig";
import LoginPage from "./loginPage";
import { InteractionType } from "@azure/msal-browser";
import PdfDocumentViewer from "./components/PdfViewer/PdfDocumentViewer";


initializeIcons();


export default function App() {
    return (
<AuthWrapper>
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
    <ReduxProvider store={store}>
        <HashRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    {/* */}
                    <Route index element={<Chat showOnlyCitation={true} />} />

                    <Route path="citation" element={<Chat showOnlyCitation={true} />} />
                    <Route path="/viewdocument/:citation" element={<PdfDocumentViewer/>} />
                    
                    <Route path="*" element={<NoPage />} />
                </Route>
                <Route path="/qa/:inputQuestion" element={<OneShot />} />

            </Routes>
        </HashRouter>
        </ReduxProvider>
    </MsalAuthenticationTemplate>
    {/* <UnauthenticatedTemplate>
        <LoginPage/>
    </UnauthenticatedTemplate> */}
</AuthWrapper>

  
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
