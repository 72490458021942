import { configureStore } from '@reduxjs/toolkit';

import rootReducer from './rootReducer';

 

const store = configureStore({

  reducer: rootReducer,

  middleware: (getDefaultMiddleware:any) => getDefaultMiddleware(), //.concat(logger),

});

export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}

export type AppDispatch = typeof store.dispatch;

 

export default store;