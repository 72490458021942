export const enum Approaches {
    RetrieveThenRead = "rtr",
    ReadRetrieveRead = "rrr",
    ReadDecomposeAsk = "rda"
}

export type AskRequestOverrides = {
    semanticRanker?: boolean;
    semanticCaptions?: boolean;
    excludeCategory?: string;
    top?: number;
    temperature?: number;
    promptTemplate?: string;
    promptTemplatePrefix?: string;
    promptTemplateSuffix?: string;
    suggestFollowupQuestions?: boolean;
};

export type AskRequest = {
    question: string;
    approach: Approaches;
    overrides?: AskRequestOverrides;
};

export type AskResponse = {
    answer: string;
    externalAnswer:string;
    thoughts: string | null;
    data_points: string[];
    error?: string;
    allContents:AllContents[];
    common_part:CommonPart;
};
export type AllContents ={
    answer: string;
    data_points: string[];
    group_name: string;
    score: number;
    thoughts: string;
    timeSpan: TimeSpan;
    externalAnswer:string;
  }

  export type TimeSpan ={
    FirstCall:number,
    SearchMethod:number,
    SecondCall: number;
    totalTime: number;
  }
  export type CommonPart={
    timeSpan:TimeSpan;
    common_thoughts:string;
  }
  export type NextChatRequest = {
    allContents: AllContents[];
    common_part: CommonPart;
    nextGroup?: string;
    allowWeb:boolean;
    search_method?:string;
    allowEmtec:boolean;
    overrides?: AskRequestOverrides;
    transId?: string;
};
export type ApiRequest ={
    message:string;
    userId:string;
    sessionId:string;
    transId?: string;
}

export type DataResponse = {
    data: Object[];
    metadata:ResultMetadata[];
    resultType: string;
    error?: string;
};

export type ResultMetadata ={
    name:string;
    type:string;
}
export type ChatTurn = {
    user: string;
    bot?: string;
};

export type ChatRequest = {
    history: ChatTurn[];
    approach: Approaches;
    overrides?: AskRequestOverrides;
    allowWeb:boolean;
    allowLink:boolean;
    search_method?:string;
    enableSemanticSearch:boolean;
    transId?: string;
};

export type Options = {
    allowWeb:boolean;
    allowLink:boolean;
    allowData:boolean;
    search_method?:string;
    enableSemanticSearch:boolean;
};

export type ThoughtProcessOptions = {
    viewThoughtProcess:boolean;
    viewSupportingContent:boolean;
};
export type FeedbackRequest = {
    FeedbackType: string;
    Comment?: string;
    Question: string;
    SearchType: string;
    AnswerSummary?: string;
    TransId: string;
};