import { useRef, useState, useEffect } from "react";
import { Checkbox, Panel, DefaultButton, TextField, SpinButton, Modal, IconButton } from "@fluentui/react";
import { SparkleFilled } from "@fluentui/react-icons";

import styles from "./LLChat.module.css";

import {
    chatApi,
    Approaches,
    AskResponse,
    ChatRequest,
    ChatTurn,
    DataResponse,
    ApiRequest,
    getDataApi,
    NextChatRequest,
    CommonPart,
    nextChatAnswerApi,
    FeedbackRequest,
    savefeedback
} from "../../api";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { ExampleList } from "../../components/Example";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { SettingsButton } from "../../components/SettingsButton";
import { ClearChatButton } from "../../components/ClearChatButton";
import Layout from "../layout/Layout";
import { WebAnswer } from "../../components/WebAnswer";
import LLLogo from "../../assets/LLLogo.svg";
import GPTLogo from "../../assets/GPT.svg";
import { useSelector } from "react-redux";
import { useAppSelector } from "../../hooks";
import { DbAnswer } from "../../components/DbAnswer";
import { DefaultAzureCredential, InteractiveBrowserCredential } from "@azure/identity";
// we're using these objects from the storage sdk - there are others for different needs
import { BlobServiceClient, BlobItem } from "@azure/storage-blob";
import PdfDocumentViewer from "../../components/PdfViewer/PdfDocumentViewer";
import { ulid } from "ulid";

interface Props {
    showOnlyCitation: boolean;
}
const Chat = ({ showOnlyCitation }: Props) => {
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const [promptTemplate, setPromptTemplate] = useState<string>("");
    const [retrieveCount, setRetrieveCount] = useState<number>(3);
    const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(true);
    const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
    const [excludeCategory, setExcludeCategory] = useState<string>("");
    const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] = useState<boolean>(false);

    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();
    const [isErrorinNext, setIsErrorinNext] = useState<unknown>();

    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);

    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [answers, setAnswers] = useState<[user: string, response: AskResponse][]>([]);
    const [dbAnswers, setDbAnswers] = useState<[user: string, response: DataResponse][]>([]);
    const [externalAnswers, setExternalAnswers] = useState<[user: string, response: AskResponse][]>([]);
    const [userId, setUserId] = useState<string>("");
    const [sessionId, setSessionId] = useState<string>("");
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [isLoadingNextAns, setIsLoadingNextAns] = useState<boolean>(false);
    const searchOptions = useAppSelector(state => state.searchOptionsState.options);

    const [currentGroupAnswerIndex, setCurrentGroupAnswerIndex] = useState<number[]>([1]);
    const [transId, setTransId] = useState<string[]>([]);

    const makeApiRequest = async (question: string) => {
        lastQuestionRef.current = question;
        error && setError(undefined);
        setIsLoading(true);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        if (searchOptions.allowData) {
            let newTransId = transId[dbAnswers.length];
            if (error === undefined) {
                newTransId = ulid(181474976710655);
                const updatedTrid = [...transId, newTransId];
                setTransId(updatedTrid);
            }
            try {
                const request: ApiRequest = {
                    message: question,
                    userId: userId,
                    sessionId: sessionId,
                    transId: newTransId,
                };

                const result = await getDataApi(request);
                var json = result.data;
                setDbAnswers([...dbAnswers, [question, result]]);
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        } else {
            try {
                let newTransId = transId[answers.length];
                if (error === undefined) {
                    newTransId = ulid(181474976710655);
                    const updatedTrid = [...transId, newTransId];
                    setTransId(updatedTrid);
                }
                const history: ChatTurn[] = answers.map(a => ({ user: a[0], bot: a[1].answer }));
                const request: ChatRequest = {
                    //history: [...history, { user: question, bot: undefined }],
                    history: [{ user: question, bot: undefined }],
                    approach: Approaches.ReadRetrieveRead,
                    allowLink: searchOptions.allowLink,
                    allowWeb: searchOptions.allowWeb,
                    search_method: searchOptions.search_method,
                    enableSemanticSearch: searchOptions.enableSemanticSearch,
                    transId: newTransId,
                    overrides: {
                        promptTemplate: promptTemplate.length === 0 ? undefined : promptTemplate,
                        excludeCategory: excludeCategory.length === 0 ? undefined : excludeCategory,
                        top: retrieveCount,
                        semanticRanker: useSemanticRanker,
                        semanticCaptions: useSemanticCaptions,
                        suggestFollowupQuestions: useSuggestFollowupQuestions
                    }
                };
                const result = await chatApi(request);
                setAnswers([...answers, [question, result]]);
                const updatedArray = [...currentGroupAnswerIndex]; // Create a copy of the array
                updatedArray[answers.length] = 1;
                setCurrentGroupAnswerIndex(updatedArray);
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const nextChatAnswerApiCall = async (question: string, allContent: any, common_part: CommonPart, nextGroupId: number, index: number) => {
        lastQuestionRef.current = question;
        error && setError(undefined);
        setIsLoadingNextAns(true);
        setActiveCitation(undefined);
        try {
            const history: ChatTurn[] = answers.map(a => ({ user: a[0], bot: a[1].answer }));
            const request: NextChatRequest = {
                allContents: allContent,
                common_part: common_part,
                nextGroup: nextGroupId.toString(),
                allowEmtec: searchOptions.allowLink,
                allowWeb: false,
                overrides: {
                    promptTemplate: promptTemplate.length === 0 ? undefined : promptTemplate,
                    excludeCategory: excludeCategory.length === 0 ? undefined : excludeCategory,
                    top: retrieveCount,
                    semanticRanker: useSemanticRanker,
                    semanticCaptions: useSemanticCaptions,
                    suggestFollowupQuestions: useSuggestFollowupQuestions
                }
            };
            const result = await nextChatAnswerApi(request);
            answers[index] = [question, result];
            setAnswers([...answers]);
        } catch (e) {
            setIsErrorinNext(true);
            console.log("error in next call" + e);
        } finally {
            setIsLoading(false);
            setIsLoadingNextAns(false);
        }
    };

    const clearChat = () => {
        lastQuestionRef.current = "";
        error && setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setAnswers([]);
        setExternalAnswers([]);
        setDbAnswers([]);
    };

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading]);
    useEffect(() => {
        const user = "UserId_" + Math.random().toString(36).substring(2);
        const session = Date.now().toString(36) + Math.random().toString(36).substring(2);
        setSessionId(session);
        setUserId(user);
    }, []);

    useEffect(() => {
        clearChat();
    }, [searchOptions.allowData, searchOptions.allowLink, searchOptions.allowWeb, searchOptions.search_method, searchOptions.enableSemanticSearch]);

    const onPromptTemplateChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPromptTemplate(newValue || "");
    };

    const onRetrieveCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setRetrieveCount(parseInt(newValue || "3"));
    };

    const onUseSemanticRankerChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticRanker(!!checked);
    };

    const onUseSemanticCaptionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticCaptions(!!checked);
    };

    const onExcludeCategoryChanged = (_ev?: React.FormEvent, newValue?: string) => {
        setExcludeCategory(newValue || "");
    };

    const onUseSuggestFollowupQuestionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSuggestFollowupQuestions(!!checked);
    };

    const onExampleClicked = (example: string) => {
        makeApiRequest(example);
    };

    const onShowCitation = (citation: string, index: number) => {
        if (activeCitation === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveCitation(citation);
            setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
        }
        setSelectedAnswer(index);
    };

    const onShowWebCitation = async (citation: string, index: number) => {
        if (citation != undefined) {
            if (showOnlyCitation && !citation.includes("https://")) {
                window.open(`${citation}`, "_blank", "noreferrer");
            } else if (showOnlyCitation && citation.includes("https://")) {
                window.open(`${citation}`, "_blank", "noreferrer");
            } else {
                if (activeCitation === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index) {
                    setActiveAnalysisPanelTab(undefined);
                } else {
                    setActiveCitation(citation);
                    setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
                }
            }
            setSelectedAnswer(index);
        }
    };

    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);
        }

        setSelectedAnswer(index);
    };
    const onClickViewMultipleAnswer = (event: any, name: string, index: number = 0) => {
        event.preventDefault();
        setSelectedAnswer(index);
        console.log("array", currentGroupAnswerIndex);
        if (name === "prevAnswer") {
            if (currentGroupAnswerIndex[index] == 1) {
                currentGroupAnswerIndex[index] = 1;
                setCurrentGroupAnswerIndex([...currentGroupAnswerIndex]);
                return;
            } else {
                currentGroupAnswerIndex[index] = currentGroupAnswerIndex[index] - 1;
                setCurrentGroupAnswerIndex([...currentGroupAnswerIndex]);
                return;
            }
        } else {
            let count = 0;
            let i;
            for (i in answers[index][1].allContents) {
                if (answers[index][1].allContents.hasOwnProperty(i)) {
                    count++;
                }
            }
            if (count <= currentGroupAnswerIndex[index]) {
                return;
            } else {
                setIsErrorinNext(false);
                if (answers[index][1].allContents[currentGroupAnswerIndex[index] + 1].answer === null) {
                    nextChatAnswerApiCall(
                        answers[index][0],
                        answers[index][1].allContents,
                        answers[index][1].common_part,
                        currentGroupAnswerIndex[index] + 1,
                        index
                    );
                }
                currentGroupAnswerIndex[index] = currentGroupAnswerIndex[index] + 1;
                setCurrentGroupAnswerIndex([...currentGroupAnswerIndex]);
            }
        }
    };
    const saveFeedback = (index: number, comment: string, feedbackType: string, question: string, AnswerSummary: string = "") => {
        let feedback: FeedbackRequest = {
            FeedbackType: feedbackType,
            Comment: comment,
            Question: question,
            SearchType: searchOptions.allowData ? "Data" : "Document",
            AnswerSummary: AnswerSummary,
            TransId: transId[index]
        };
        savefeedback(feedback);
    };

    return (
        <div className={styles.container}>
            <div className={styles.commandsContainer}>
                <ClearChatButton className={styles.commandButton} onClick={clearChat} disabled={!lastQuestionRef.current || isLoading} />
                {/* <SettingsButton className={styles.commandButton} onClick={() => setIsConfigPanelOpen(!isConfigPanelOpen)} /> */}
            </div>
            <div className={styles.chatRoot}>
                <div className={styles.chatContainer}>
                    {!lastQuestionRef.current ? (
                        <div className={styles.chatEmptyState}>
                            <img src={LLLogo} />
                            <img src={GPTLogo} height={"18px"} className={styles.GPTLogo} />
                            {/* <h1 className={styles.chatEmptyStateTitle}>Search pdfs</h1>
                            <h2 className={styles.chatEmptyStateSubtitle}>Ask anything or try an example</h2> */}
                        </div>
                    ) : (
                        <div className={styles.chatMessageStream}>
                            {answers.map((answer, index) => (
                                <div key={index}>
                                    <UserChatMessage message={answer[0]} />
                                    {searchOptions.allowLink && (
                                        <>
                                            <div className={styles.chatMessageGpt}>
                                                {((!isLoadingNextAns && selectedAnswer === index) || selectedAnswer !== index) && (
                                                    <Answer
                                                        key={index}                                                        
                                                        answer={answer[1].allContents[currentGroupAnswerIndex[index]]}
                                                        isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                                        onCitationClicked={c => onShowCitation(c, index)}
                                                        onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                        onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                                        onFollowupQuestionClicked={q => makeApiRequest(q)}
                                                        showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                                        onClickViewMultipleAnswer={(event: any, name: string) => onClickViewMultipleAnswer(event, name, index)}
                                                        totalGroups={Object.keys(answer[1].allContents).length}
                                                        selectedGroup={currentGroupAnswerIndex[index]}
                                                        saveFeedback={(comment: string, feedbackType: string) =>
                                                            saveFeedback(index, comment, feedbackType, answer[0])
                                                        }
                                                    />
                                                )}
                                                {((isLoadingNextAns && selectedAnswer === index) ||
                                                    answer[1].allContents[currentGroupAnswerIndex[index]] === null) && (
                                                    <div className={styles.chatMessageGptMinWidth} style={{ width: "80%" }}>
                                                        <AnswerLoading />
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    )}
                                    {searchOptions.allowWeb && (
                                        <div className={styles.chatMessageGpt}>
                                            <WebAnswer
                                                key={index}
                                                answer={answer[1]}
                                                isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                                onCitationClicked={c => onShowWebCitation(c, index)}
                                                onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                                onFollowupQuestionClicked={q => makeApiRequest(q)}
                                                showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                            />
                                        </div>
                                    )}
                                </div>
                            ))}
                            {dbAnswers.map((answer, index) => (
                                <div key={index}>
                                    <UserChatMessage message={answer[0]} />
                                    {searchOptions.allowData && (
                                        <div className={styles.chatMessageGpt}>
                                            <DbAnswer
                                                key={index}
                                                answer={answer[1]}
                                                isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                                saveFeedback={(comment: string, feedbackType: string) =>
                                                    saveFeedback(index, comment, feedbackType, answer[0])
                                                }
                                                // onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                // onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                                // onFollowupQuestionClicked={q => makeApiRequest(q)}
                                                // showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                            />
                                        </div>
                                    )}
                                </div>
                            ))}
                            {isLoading && (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerLoading />
                                    </div>
                                </>
                            )}
                            {error ? (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerError 
                                        error={
                                            searchOptions.allowData ? "Sorry, no results found. You can try by rephrasing the question." : error.toString()
                                        } onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                    </div>
                                </>
                            ) : null}
                            <div ref={chatMessageStreamEnd} />
                        </div>
                    )}
                </div>

                {answers.length > 0 && activeAnalysisPanelTab && (
                    <AnalysisPanel
                        className={styles.chatAnalysisPanel}
                        activeCitation={activeCitation}
                        onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                        citationHeight="810px"
                        //answer={answers[selectedAnswer][1]}
                        answer={answers[selectedAnswer][1].allContents[currentGroupAnswerIndex[selectedAnswer]]}
                        activeTab={activeAnalysisPanelTab}
                        common_thoughts={answers[selectedAnswer][1].common_part.common_thoughts}
                    />
                )}

                <Panel
                    headerText="Configure answer generation"
                    isOpen={isConfigPanelOpen}
                    isBlocking={false}
                    onDismiss={() => setIsConfigPanelOpen(false)}
                    closeButtonAriaLabel="Close"
                    onRenderFooterContent={() => <DefaultButton onClick={() => setIsConfigPanelOpen(false)}>Close</DefaultButton>}
                    isFooterAtBottom={true}
                >
                    <TextField
                        className={styles.chatSettingsSeparator}
                        defaultValue={promptTemplate}
                        label="Override prompt template"
                        multiline
                        autoAdjustHeight
                        onChange={onPromptTemplateChange}
                    />

                    <SpinButton
                        className={styles.chatSettingsSeparator}
                        label="Retrieve this many documents from search:"
                        min={1}
                        max={50}
                        defaultValue={retrieveCount.toString()}
                        onChange={onRetrieveCountChange}
                    />
                    <TextField className={styles.chatSettingsSeparator} label="Exclude category" onChange={onExcludeCategoryChanged} />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticRanker}
                        label="Use semantic ranker for retrieval"
                        onChange={onUseSemanticRankerChange}
                    />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticCaptions}
                        label="Use query-contextual summaries instead of whole documents"
                        onChange={onUseSemanticCaptionsChange}
                        disabled={!useSemanticRanker}
                    />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSuggestFollowupQuestions}
                        label="Suggest follow-up questions"
                        onChange={onUseSuggestFollowupQuestionsChange}
                    />
                </Panel>
            </div>
            <div className={styles.chatInput}>
                <QuestionInput clearOnSend placeholder="Type next question here..." disabled={isLoading} onSend={question => makeApiRequest(question)} />
            </div>
        </div>
    );
};

export default Chat;
